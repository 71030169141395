import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import Layout from '../../components/Layout'
import { ChevronRight } from 'react-feather';
import {TransitionLink} from 'gatsby-plugin-transition-link/components/TransitionLink';
import {TransitionPortal} from 'gatsby-plugin-transition-link'
import {TweenLite} from 'gsap'

export default class BlogOverviewPage extends React.Component {
  blogElements = [];
  transitionPortal = null;

  setBlogElementRef = (el, index) => this.blogElements[index] = el;

  navigateTo = (page) => {
    navigate(page);
  };

  animateLeaveBlogOverview = ({exit: {state, length: duration}, node}) => {
    const transitionElementRect = state.transitionElement.getBoundingClientRect();
    state.portal.replaceChild(state.transitionElement, state.portal.firstChild);

    TweenLite.set(state.portal,{
      display: 'block',
      position: 'absolute',
      width: transitionElementRect.width,
      height: transitionElementRect.height,
      top: transitionElementRect.top,
      left: transitionElementRect.left,
    });
    TweenLite.fromTo(node, duration, { opacity: 1 }, { opacity: 0 });
    // console.log('animateLeaveBlogOverview', transitionElementRect, state.transitionElement, state.portal);
  };

  componentDidMount() {
    setTimeout(() => {
      if(this.blogElements[0]) {
        this.blogElements[0].firstChild.classList.add('hover')
        setTimeout(() => {
          if(this.blogElements[0]) {
            this.blogElements[0].firstChild.classList.remove('hover')
          }
        }, 500)
      }
    }, 800)
  }

  animateOpenBlogPost = ({entry: {state, length: duration}, node}) => {
    const target = node.querySelector('h1');
    const targetStyles = window.getComputedStyle(target);
    const targetRect = target.getBoundingClientRect();
    // console.log('animateOpenBlogPost', target, targetRect, node.querySelector('h1'), state.portal, state.transitionElement);

    if (state.portal) {
      const portalMoveDuration = duration * 0.9;
      const caretMoveDuration = portalMoveDuration * 0.2;
      TweenLite.set(target, { opacity: 0 });
      TweenLite.to(state.portal, portalMoveDuration, {
        width: targetRect.width,
        height: targetRect.height,
        top: targetRect.top + document.scrollingElement.scrollTop,
        left: targetRect.left,
      });
      TweenLite.to(state.transitionElement, portalMoveDuration, {
        fontSize: targetStyles.fontSize,
        fontFamily: targetStyles.fontFamily,
        lineHeight: targetStyles.lineHeight,
        color: targetStyles.color,
      });
      TweenLite.to(state.transitionElement.querySelector('.caret-animated'), caretMoveDuration, {
        width: '35px',
      });
      TweenLite.fromTo(node, duration, { opacity: 0 }, { opacity: 1 });
      TweenLite.delayedCall(duration, () =>
        TweenLite.set(target, { opacity: 1 }))
    }
  };

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Layout>
        <section className="section">
          <div className="container blog-overview">
            <div className="content blog-overview-intro">
              <h1 className="has-text-weight-bold is-size-2">Blog</h1>
              <p>Every now and then I like to tidy up my thoughts and experiences and write it down into a blog post.<br/>
                I sincerely hope there are some helpful bits to you and I'd be more than happy to hear about it.</p>
              <p>I would also like to invite you to follow me on Medium and connect on LinkedIn to keep in touch.</p>
            </div>
            <TransitionPortal>
              <div ref={ref => this.transitionPortal = ref}><span></span></div>
            </TransitionPortal>
            {posts
              .map(({ node: post }, index) => (
                <TransitionLink
                  exit={{
                    trigger: (data) => this.animateLeaveBlogOverview(data),
                    state: { transitionElement: this.blogElements[index], portal: this.transitionPortal },
                    length: 0.3
                  }}
                  entry={{
                    trigger: (data) => this.animateOpenBlogPost(data),
                    state: { transitionElement: this.blogElements[index], portal: this.transitionPortal },
                    length: 0.3
                  }}
                  className="blog-entry-link"
                  activeClassName="is-active"
                  to={post.fields.slug}
                  key={post.id}>
                  <div className="blog-entry">
                    <div className="blog-entry-header" ref={ref => this.setBlogElementRef(ref, index)}>
                      <div className="caret-animated"><ChevronRight /></div>
                      {post.frontmatter.title}
                    </div>
                    <div className="blog-entry-description">
                      <small>{post.frontmatter.date}</small>
                    </div>
                    <div className="blog-entry-content">
                      <p>
                        {post.excerpt}
                      </p>
                    </div>
                  </div>
                </TransitionLink>
              ))}
          </div>
        </section>
      </Layout>
    )
  }
}

BlogOverviewPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query BlogOverviewQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
